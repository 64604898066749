import React from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../component/Footer/Footer";
import Logo from "../assets/company-logo.png";
import BackIcon from "../assets/left.png";
import "./AboutUs.css";

function AboutUs() {
    const navigate = useNavigate();
    const handleBack = () => {
        navigate("/catalogue");
    };

    return (
        <div>
            <div className="aboutus-container">
                <div className="static-page-back">
                    <img src={BackIcon} alt="Back" onClick={handleBack} />
                </div>
                <div className="logo-container">
                    <img src={Logo} alt="Company Logo" className="company-logo" />
                </div>
                <h1 className="aboutus-title">About Us</h1>

                <ol className="aboutus-list">
                    <p>
                        Welcome to La Milano Pizzeria, brand owned by MFW Foods Private Limited. If
                        you are not entirely satisfied with your purchase, we're here to help. This
                        is a platform where direct business owners (our partners whom you order are
                        La Milano Pizzeria Franchises) are solely responsible for the initiating
                        refund process. Once order placed it cann't be cancelled or refunded.
                        However in certain cases our partners can refund for which you can directly
                        contact them where their contact number is shared upon successful order from
                        your side.
                    </p>
                </ol>
                <h2 className="aboutus-subtitle">Shipping Policy (MFW Foods Pvt Ltd & La Milano Franchises)</h2>
                <ol className="aboutus-list">
                    <p>
                        We offer delivery
                        in about 30–60 minutes, but the actual time may vary. The guarantee doesn't
                        apply if the store's operating conditions aren't suitable. Charges delivery
                        for all orders, and the charge may vary based on the order value. The
                        guarantee doesn't apply on certain holidays, like New Year's Eve, Christmas,
                        and Durga Pooja. We reserve the right to withdraw the guarantee without
                        notice
                    </p>
                </ol>
                <h2 className="aboutus-subtitle">Our Network of Outlets</h2>
                <ol className="aboutus-list">
                    <div className="aboutus-table-container"> 
                        <table className="aboutus-table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Location Name</th>
                                    <th>Short Name</th>
                                    <th>Address</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>ZEROTO1-GANDHINAGAR Sector 11</td>
                                    <td>Sector 11</td>
                                    <td>
                                        1 2 , Ground Floor , Skyline, Sector 11, Gandhinagar, Gujarat
                                        382011
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>VRAJNAM LUXURY FOODS AND BEVERAGES LLP - Waghodia</td>
                                    <td>Waghodia Road (vadodara)</td>
                                    <td>
                                        G5, Samruddhi Trade Center, Nr. BPCL Petrol Pump, Waghodia
                                        Dabhoi Ring Road
                                    </td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>URBANTABLES CATERING & HOSPITALITY LLP ( Sarjapura Road )</td>
                                    <td>Sarjapura Road</td>
                                    <td>
                                        GF - 116/9, VS Arcade, Sarjapura Main Road, Near HP Petrol Pump
                                        ,Doddakanahalli, Bangalore, Karnataka-560035
                                    </td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>URBANTABLES CATERING & HOSPITALITY LLP ( Marathalli )</td>
                                    <td>Marathalli ( Bangalore )</td>
                                    <td>
                                        No 761 /1, Ground Floor, Outer Ring Road, Near KLM Mall,
                                        Marathalli, Bengaluru, Karnataka.
                                    </td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>URBAN EATERY - Nizampura</td>
                                    <td>Nizampura</td>
                                    <td>
                                        shop no 1, ground floor kunj prime opp axis bank Nizampura road
                                        tashkand society Vadodara Gujarat 390024
                                    </td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>UFO FOOD & BEVERAGES - Vijay nagar</td>
                                    <td>Vijay nagar (indore)</td>
                                    <td>
                                        S-15, Shekhar Planet, B5, Scheme No.54, P.U. 4, vijaynagar.
                                        indore.
                                    </td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>TILTON ENTERPRISE - KATARGAM (SURAT)</td>
                                    <td>KATARGAM (SURAT)</td>
                                    <td>
                                        GROUND FLOOR, SHOP NO. 21 TO 23, LAXMI ENCLAVE-2, LAXMI CIRCLE,
                                        opposite GAJERA SCHOOL, Katargam, Surat, Gujarat 395004
                                    </td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td>TANISHK FOODS - VASTRAL</td>
                                    <td>Vastral</td>
                                    <td>
                                        B-113,114,115, girivar galaxy complex, opp reliance petrol pump,
                                        sp ring road, vastral, ahmedabad, Gujarat, 382418
                                    </td>
                                </tr>
                                <tr>
                                    <td>9</td>
                                    <td>SUDARSHAN FOOD & BEVERAGES-Science city</td>
                                    <td>Science city</td>
                                    <td>
                                        G.F. Shop No.1, I Square Near Sukan Mall, Main Road, beside CIMS
                                        Hospital, Science City, Ahmedabad, Gujarat 380060
                                    </td>
                                </tr>
                                <tr>
                                    <td>10</td>
                                    <td>SHUBHAM FOODS - Radhanpur Road ( MEHSANA)</td>
                                    <td>Radhanpur Road (MEHSANA)</td>
                                    <td>
                                        Shop No. G-56,57,58,59, Seven Space, Opp. Kalapi Nagar,
                                        Radhanpur Road
                                    </td>
                                </tr>
                                <tr>
                                    <td>11</td>
                                    <td>SHREE VENTURES - Porbandar</td>
                                    <td>Porbandar</td>
                                    <td>
                                        Ground floor , shree Forthun Building , Nr Ronak Hospital , Svp
                                        Road Porbandar
                                    </td>
                                </tr>
                                <tr>
                                    <td>12</td>
                                    <td>SHREE FOODS - Palanpur</td>
                                    <td>Abu Road highway Palanpur</td>
                                    <td>
                                        Mob - 9687046000, G 55-58 Shilp Arcade Opposite Bihari Bag ,
                                        Palanpur Abu road Highway Palanpur 385001
                                    </td>
                                </tr>
                                <tr>
                                    <td>13</td>
                                    <td>M AND V ENTERPRISE -Sarangpur</td>
                                    <td>Sarangpur</td>
                                    <td>
                                        Shop no. 4 & 5, Nilkanth food court, botad-barvala highway,
                                        sarangpur , Botad
                                    </td>
                                </tr>
                                <tr>
                                    <td>14</td>
                                    <td>Rise Enterprise- Ambli Road</td>
                                    <td>Ambli bopal</td>
                                    <td>
                                        GF-1 One World West Nr Bopal Approach BRTS, Ambli - Bopal Rd,
                                        Ahmedabad, Gujarat 380059
                                    </td>
                                </tr>
                                <tr>
                                    <td>15</td>
                                    <td>URUGAY FOOD COMPANIES</td>
                                    <td>Relience Chowkdi</td>
                                    <td>
                                        Shop Number 15, Block Number B, Ground Floor, Pramukh Anand,
                                        Orbit Mall, Kudasan, Gandhinagar.
                                    </td>
                                </tr>
                                <tr>
                                    <td>16</td>
                                    <td>RAGHURAI FOOD ZONE - Nana Mava (Rajkot)</td>
                                    <td>Nana Mava (Rajkot)</td>
                                    <td>
                                        Shop No. 1&2, Maa Sharda Complex, Meghmaya Nagar, Nana Mava Main
                                        Road
                                    </td>
                                </tr>
                                <tr>
                                    <td>17</td>
                                    <td>RADHE FOOD - Anand Av Road</td>
                                    <td>Anand AV ROAD</td>
                                    <td>
                                        SHOP NO. 02 SHIV SHARANAM COMPLEX NEAR H.M.PATEL STATUE ANAND
                                        388001
                                    </td>
                                </tr>
                                <tr>
                                    <td>18</td>
                                    <td>VISHVAS GROUP OF HOSPITALITY- Prayagraj</td>
                                    <td>Prayagraj</td>
                                    <td>
                                        17C/01 Stretchy Road, Civil Lines, In front of Madan Men,
                                        Prayagraj
                                    </td>
                                </tr>
                                <tr>
                                    <td>19</td>
                                    <td>PATEL FOOD -Jakatnaka (Surat)</td>
                                    <td>Jakatnaka (Surat)</td>
                                    <td>
                                        First floor 171 to 173 Avadh viceroy Sarthana jakatnaka, Nr
                                        Dmart, varachha road surat
                                    </td>
                                </tr>
                                <tr>
                                    <td>20</td>
                                    <td>OM MAHADEV FOODS LLP - Atal Bridge</td>
                                    <td>Atal Bridge</td>
                                    <td>
                                        Shop No-6, Ground Floor, Multi Level Corporation Parking (MLCP),
                                        Sabarmati Riverfront, Opp. Atal Bridge west side, Paldi
                                    </td>
                                </tr>
                                <tr>
                                    <td>21</td>
                                    <td>Om Hospitality- PDPU (Gandhinagar)</td>
                                    <td>PDPU (Gandhinagar)</td>
                                    <td>
                                        Shop No.12, Elixir Prime Business Hub, PDPU Rd, opp. Shree Rang
                                        Upvan Bunglows, Raysan, Gujarat
                                    </td>
                                </tr>
                                <tr>
                                    <td>22</td>
                                    <td>MFW FOODS PVT LTD- Surdhara</td>
                                    <td>Surdhara</td>
                                    <td>
                                        Shop 24 Maple Trade Center nr, Surdhara Cir, Thaltej, Ahmedabad,
                                        Gujarat 380054
                                    </td>
                                </tr>
                                <tr>
                                    <td>23</td>
                                    <td>MFW FOODS PVT LTD- Prahladnagar</td>
                                    <td>PRAHLADNAGAR</td>
                                    <td>
                                        GF 7&8, Pushpak, opp. AUDA Garden, Prahlad Nagar, Ahmedabad,
                                        Gujarat 380015
                                    </td>
                                </tr>
                                <tr>
                                    <td>24</td>
                                    <td>MFW FOODS PVT LTD- Manekbaugh</td>
                                    <td>MANEKBAUGH</td>
                                    <td>
                                        SHOP NO.5 SUN AVENUE 1 MANEKBAUGH SHYAMAL ROAD, MANEKBAUGH,
                                        AHMEDABAD -380015
                                    </td>
                                </tr>
                                <tr>
                                    <td>25</td>
                                    <td>MFW FOODS PVT LTD- Bapunagar</td>
                                    <td>Bapunagar</td>
                                    <td>
                                        Shakti Dhara Complex, 30/31, Ground Floor, Opp. Kakdiya
                                        Hospital, Bapunagar, Ahmedabad, Gujarat 380024
                                    </td>
                                </tr>
                                <tr>
                                    <td>26</td>
                                    <td>MFW FOODS PVT LTD - Vastrapur</td>
                                    <td>Vastrapur</td>
                                    <td>
                                        Shop 18, Galaxy Bazzar Opp Himalaya Mall, Vastrapur, Ahmedabad,
                                        Gujarat 380052
                                    </td>
                                </tr>
                                <tr>
                                    <td>27</td>
                                    <td>MFW FOODS PVT LTD - Nikol</td>
                                    <td>Nikol</td>
                                    <td>
                                        Gf - Shop No. 20,22,23, Town Plaza Near Raspan Arcade, Opp.
                                        Madhav Bungalows, Raspan Cross Rd, Nikol, Ahmedabad, 382350
                                    </td>
                                </tr>
                                <tr>
                                    <td>28</td>
                                    <td>MFW FOODS PVT LTD - Bodakdev</td>
                                    <td>Bodakdev</td>
                                    <td>
                                        Circle B, Shop 10, Judges Bunglow Rd, Bodakdev, Ahmedabad,
                                        Gujarat 380054
                                    </td>
                                </tr>
                                <tr>
                                    <td>29</td>
                                    <td>MARUTI HOSPITALITY- MANINAGAR</td>
                                    <td>Maninagar</td>
                                    <td>
                                        BRTS Bus Stop, Shop 9 to 16 Swagat Complex oppo, Rambagh,
                                        Maninagar, Ahmedabad, Gujarat 380008
                                    </td>
                                </tr>
                                <tr>
                                    <td>30</td>
                                    <td>MARUTI HOSPITALITY- GHODASAR</td>
                                    <td>Ghodasar</td>
                                    <td>
                                        Shop 3, Shreenath Signet, In Front Of Ghodasar BRTS Stand,
                                        Ghodasar char rasta, Gujarat 382443
                                    </td>
                                </tr>
                                <tr>
                                    <td>31</td>
                                    <td>SHIVOHAM FOODS-Manjalpur</td>
                                    <td>Manjalpur</td>
                                    <td>
                                        Shop No. 1,2 Fortune Platinum, Tulsidham Circle, Opp. Hotel Blue
                                        Lagoon, Manjalpur, Vadodara.
                                    </td>
                                </tr>
                                <tr>
                                    <td>32</td>
                                    <td>M AND G ENTERPRISE - Sanala Road Morbi</td>
                                    <td>Sanala Road (Morbi)</td>
                                    <td>G 7-8, star arcade, opp sky mall, sanala road, morbi 363641</td>
                                </tr>
                                <tr>
                                    <td>33</td>
                                    <td>ANUKRITI ENTERPRISES-Lucknow</td>
                                    <td>Lucknow</td>
                                    <td>
                                        Shop No.4, Ground Floor, Laxmi Apartment, Hardoi Road, Lucknow
                                    </td>
                                </tr>
                                <tr>
                                    <td>34</td>
                                    <td>KRISHNA HOSPITALITY - SHYAMAL</td>
                                    <td>Shyamal</td>
                                    <td>
                                        GF, Golden Icon oppo Medilink Hospital, Shyamal to, 132 Feet
                                        Ring Rd, Shivranjani, Ahmedabad, Gujarat 380015
                                    </td>
                                </tr>
                                <tr>
                                    <td>35</td>
                                    <td>KRISHNA HOSPITALITY - Race Course (Vadodara)</td>
                                    <td>Race Course (Vadodara)</td>
                                    <td>
                                        shop No. 7 & 8, KP Infinity, Race Course, vadodara, Gujarat
                                        390007
                                    </td>
                                </tr>
                                <tr>
                                    <td>36</td>
                                    <td>KESHIV FOODS - HIMATNAGAR</td>
                                    <td>Himatnagar (Gujrat)</td>
                                    <td>
                                        Block A - Shop No. 1, Signature Business Hub, Nr. Science
                                        College, Himmatnagar, Sabarkantha
                                    </td>
                                </tr>
                                <tr>
                                    <td>37</td>
                                    <td>DWIJA ENTERPRISE - Kadi</td>
                                    <td>Kadi</td>
                                    <td>
                                        B-Gf -35,36 Hanumant Plaza, Near Wadvala Hanuman Mandir Kadi -
                                        Kalyanpur Road, Kadi
                                    </td>
                                </tr>
                                <tr>
                                    <td>38</td>
                                    <td>MAHADEV ENTERPRISE - Junagadh</td>
                                    <td>Junagadh</td>
                                    <td>
                                        kalpavrux complex ground floor shop no 4 opposite - yamuna vadi
                                        zanzarda road JUNAGADH GUJARAT
                                    </td>
                                </tr>
                                <tr>
                                    <td>39</td>
                                    <td>JAY AMBE CREATION - NARODA</td>
                                    <td>Naroda (Ahmedabad)</td>
                                    <td>
                                        G-4 Kayakalp avenue Nr. Galaxy under bridge NH-8 Naroda
                                        -Ahmedabad 382330
                                    </td>
                                </tr>
                                <tr>
                                    <td>40</td>
                                    <td>GRP Foods - Hyderabad</td>
                                    <td>Himayat Nagar (Hyderabad)</td>
                                    <td>
                                        Shop 2A, 3-6-431, Jamuna Plaza, Himayatnagar, Hyderabad- 500029
                                    </td>
                                </tr>
                                <tr>
                                    <td>41</td>
                                    <td>GRP FOOD AND BEVERAGES - PATAN (GUJRAT)</td>
                                    <td>PATAN (GUJRAT)</td>
                                    <td>
                                        Ground floor, Suvidhi square, near padamnath chokdi, patan
                                        -384265
                                    </td>
                                </tr>
                                <tr>
                                    <td>42</td>
                                    <td>FUSION FOOD & BEVERAGES-VANDEMATRAM</td>
                                    <td>vandematram</td>
                                    <td>
                                        Ground Floor, Shop No.4-5, Aaryan Eureka Opp. Shayona Shikhar,
                                        NR Vandematram Cross Rd, near Dev Residency, Gota, Ahmedabad,
                                        Gujarat 382481
                                    </td>
                                </tr>
                                <tr>
                                    <td>43</td>
                                    <td>CRAVINGS HOSPITALITY - JABALPUR</td>
                                    <td>JABALPUR (MADHYAPRADESH)</td>
                                    <td>
                                        Infront of Maharshi school, near 4th bridge, Napier town
                                        Jabalpur
                                    </td>
                                </tr>
                                <tr>
                                    <td>44</td>
                                    <td>BROTHERS ENTERPRISE - Godhra</td>
                                    <td>Godhra</td>
                                    <td>
                                        4-5 ORCHID, OPP PENTALOONS SHOWROOM VAVDI, BESIDE HP PETROL PUMP
                                        GODHRA 389001
                                    </td>
                                </tr>
                                <tr>
                                    <td>45</td>
                                    <td>Vamja Food & Beverages -Box Park</td>
                                    <td>Box Park</td>
                                    <td>
                                        Sarkhej - Gandhinagar Hwy, opp. Jaguar Car Showroom, Gota,
                                        Ahmedabad, Gujarat 382481
                                    </td>
                                </tr>
                                <tr>
                                    <td>46</td>
                                    <td>BALAJI FOOD CORPORATION-Ayodhya</td>
                                    <td>Ayodhya (Uttar Pradesh)</td>
                                    <td>
                                        Sabri Food Plaza and Adventure Park, Opposite Park inn by
                                        Radisson Hotel, Civil lines Ayodhya
                                    </td>
                                </tr>
                                <tr>
                                    <td>47</td>
                                    <td>ARSAM IMPEX PVT LTD- Shahibaug</td>
                                    <td>Shahibaug (Ahmedabad)</td>
                                    <td>GF-2 Siddhi Vinayak Business Hub, Ghever Circle, Sahibaug.</td>
                                </tr>
                                <tr>
                                    <td>48</td>
                                    <td>ARSAM IMPEX PVT LTD- NAVRANGPURA</td>
                                    <td>NAVRANGPURA</td>
                                    <td>
                                        GF-1, campus corner, st.xaviers college corner, Navrangpura,
                                        Ahmedabad, Gujarat 380009
                                    </td>
                                </tr>
                                <tr>
                                    <td>49</td>
                                    <td>ARSAM IMPEX PVT LTD- NARANPURA</td>
                                    <td>NARANPURA</td>
                                    <td>
                                        Binali Complex, GF 17, Naranpura Rd, Opposite Torrent Power
                                        Office, Naranpura, Ahmedabad, Gujarat 380013
                                    </td>
                                </tr>
                                <tr>
                                    <td>50</td>
                                    <td>ARSAM IMPEX PVT LTD- Bhatt Circle</td>
                                    <td>Bhatt Circle</td>
                                    <td>
                                        Radhe Fortune, GF-48, Complex, Airport-Gandhinagar Highway,
                                        Ahmedabad, Gujarat 382424
                                    </td>
                                </tr>
                                <tr>
                                    <td>51</td>
                                    <td>ARSAM IMPEX PVT LTD -4 D Chandkheda</td>
                                    <td>4 D Chandkheda</td>
                                    <td>
                                        GF-29 4D Square Mall Nr. Sangat Mall, Visat, Sarkhej -
                                        Gandhinagar Hwy, Chandkheda, Ahmedabad, Gujarat 380005
                                    </td>
                                </tr>
                                <tr>
                                    <td>52</td>
                                    <td>ARSAM IMPEX PVT LTD - Shivalik NAVA VADAJ</td>
                                    <td>Shivalik NAVA VADAJ</td>
                                    <td>
                                        Bus Stand, GF-1 Shivalik Square Opp. Ranip, RTO Cir, Nava Vadaj,
                                        Ahmedabad, Gujarat 380027
                                    </td>
                                </tr>
                                <tr>
                                    <td>53</td>
                                    <td>ANTARIM WAYSIDE LLP Bharuch</td>
                                    <td>Bharuch</td>
                                    <td>Next to Bharuch RTO NH 48, Vadadla, Bharuch 392130</td>
                                </tr>
                                <tr>
                                    <td>54</td>
                                    <td>JAY AMBE CREATION - Ambaji</td>
                                    <td>Ambaji</td>
                                    <td>
                                        Vikas Avanue, Nr.Shree Hotel Opp. Reva Prabhu Sadan, Danta
                                        Highway, Ambaji -385110
                                    </td>
                                </tr>
                                <tr>
                                    <td>55</td>
                                    <td>AKSHAR FOODS - GHATLODIYA</td>
                                    <td>Ghatlodia</td>
                                    <td>
                                        Shop No. 14 & 15 S One Commercial Shayona City Circle, R.C.
                                        Technical Rd, Ghatlodia, Ahmedabad, Gujarat 380061
                                    </td>
                                </tr>
                                <tr>
                                    <td>56</td>
                                    <td>ADIDEV INNOVATIVES - Bhavnagar</td>
                                    <td>Bhavnagar</td>
                                    <td>FF-8, Victoria Bliss, Jewels Road, Bhavnagar 364002</td>
                                </tr>
                                <tr>
                                    <td>57</td>
                                    <td>A.S. Enterprises Jodhpur (Rajasthan)</td>
                                    <td>Jodhpur (Rajasthan)</td>
                                    <td>
                                        E -104, 105, Silver hotel, Near Kalpataru cinema, shastri nagar,
                                        jodhpur
                                    </td>
                                </tr>
                                <tr>
                                    <td>58</td>
                                    <td>A FINE FOOD CLUB- Kalavad Road (Rajkot)</td>
                                    <td>Kalavad Road (Rajkot)</td>
                                    <td>Knockout game zone Kalavad Road, Rajkot 360005</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </ol>
                
            </div>
            <Footer />
        </div>
    );
}

export default AboutUs;
