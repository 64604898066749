// ScrollToTopButton.js
import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { Fab } from "@mui/material";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";

const ScrollBottomToTop = () => {
    const [isVisible, setIsVisible] = useState(false);

    // Scroll event to handle button visibility
    useEffect(() => {
        const toggleVisibility = () => {
            if (window.scrollY > 300) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };
        window.addEventListener("scroll", toggleVisibility);

        return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    // Scroll to top function
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <>
            {isVisible && (
                <Tooltip title="Move to top" placement="left" arrow>
                    <Fab
                        aria-label="scroll-to-top"
                        onClick={scrollToTop}
                        style={{
                            position: "fixed",
                            bottom: 20,
                            right: 20,
                            width: "45px",
                            height: "45px",
                            zIndex: 1000,
                            background: "var(--offerhover)",
                        }}
                    >
                        <KeyboardArrowUpRoundedIcon style={{ fontSize: "2rem" }} />
                    </Fab>
                </Tooltip>
            )}
        </>
    );
};

export default ScrollBottomToTop;
