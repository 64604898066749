import React, { useEffect, useState } from 'react';
import { Box, Drawer, Button, Typography, TextField, Autocomplete, LinearProgress, CircularProgress } from "@mui/material";
// import { GoogleMap, useJsApiLoader, StandaloneSearchBox } from '@react-google-maps/api';
import { useRef } from 'react';
import './DrawerComp.css'; // External CSS file
import axiosApiFunction from '../../utils/axiosapifunc';
import axios from 'axios';
import Spinner from '../Spinner/Spinner';

export default function DrawerComp({ isDrawerOpen, setIsDrawerOpen, handleSubmit }) {
    const houseFlatRegex = /^[a-zA-Z0-9\s\-\/#]+$/;
    const addressRegex = /^[a-zA-Z0-9\s,.\-\/#]+$/;
    const cityRegex = /^[a-zA-Z\s]+$/;

    const inputref = useRef(null);
    const debounceTimeout = useRef(null);

    const [isDelivery, setIsDelivery] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isCircularLoading, setIsCircularLoading] = useState(false);

    const [deliveryData, setDeliveryData] = useState({
        houseFlatNo: '',
        landmark: '',
        address: '',
        city: ''
    });

    const [errors, setErrors] = useState({
        houseFlatNoError: '',
        landmarkError: '',
        addressError: '',
        cityError: '',
    });
    const [suggestions, setSuggestions] = useState([]);

    const token = JSON.parse(localStorage.getItem("tokenData"))?.token;

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setDeliveryData((prevState) => ({
            ...prevState,
            [name]: value
        }));

        setErrors((prevErrors) => ({
            ...prevErrors,
            [`${name}Error`]: '' // Clear the specific error dynamically
        }));

    };

    const validateFields = (deliveryData) => {
        const { houseFlatNo, landmark, address, city } = deliveryData;
        let errors = {};

        // Validate House/Flat No
        if (!houseFlatNo) {
            errors.houseFlatNoError = "House/Flat No. is required.";
        } else if (!houseFlatRegex.test(houseFlatNo)) {
            errors.houseFlatNoError = "House/Flat No. must be 1-15 characters long and can include (A-Z, a-z), (0-9), spaces, (-), (/), and (#).";
        }
        // Validate Address
        // if (!landmark) {
        //     errors.landmarkError = "Landmark is required.";
        // } else if (!addressRegex.test(landmark)) {
        //     errors.addressError = "Lankmark must be 5-150 characters long and can include (A-Z, a-z), (0-9), spaces, (,), (.), (-), (/), and (#).";
        // }

        // Validate Address
        if (!address) {
            errors.addressError = "Address is required.";
        } else if (!addressRegex.test(address)) {
            errors.addressError = "Address must be 5-150 characters long and can include (A-Z, a-z), (0-9), spaces, (,), (.), (-), (/), and (#).";
        }

        // Validate City
        if (!city) {
            errors.cityError = "City is required.";
        } else if (!cityRegex.test(city)) {
            errors.cityError = "City must be 2-50 characters long and can include only letters (A-Z, a-z) and spaces.";
        }

        return errors;
    };

    const handleAutoComplete = (e) => {
        const { name, value } = e.target;
        setDeliveryData((prevData) => ({ ...prevData, [name]: value }));
        try {
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }
            setIsCircularLoading(true);
            
            // Set a new timeout to trigger the API call after 500ms
            debounceTimeout.current = setTimeout(async () => {
                if (value?.length > 1) {
                    try {
                        const response = await axiosApiFunction("get", "/api/v1/getaddresssuggestions", { q: value }, token);
        
                        let predictions = Object.entries(response.data.data).map(([place_id, description]) => ({
                            description: description,
                            place_id: place_id
                        }));
        
                        setSuggestions(predictions);
                        setIsCircularLoading(false);
                    } catch (error) {
                        console.error('Error fetching autocomplete suggestions:', error);
                        setIsCircularLoading(false);
                    }
                } else {
                    setIsCircularLoading(false);
                    setSuggestions([]);
                }
            }, 1000);
        } catch (error) {
            console.error('Error fetching suggestions:', error);
            setIsCircularLoading(false);
        }
    };
    

    const handleSuggestionSelect = async (selectedSuggestion) => {
        setIsLoading(true);
        try {
            const { place_id } = selectedSuggestion;

            let placeDetailsResponse = await axiosApiFunction( "get", "/api/v1/getsuggestedlocationdetails", { placeid: place_id }, token );

            let placeDetails = placeDetailsResponse.data.data;
            // Fill in the address fields based on the selected place
            setDeliveryData((prevData) => ({
                ...prevData,
                address: placeDetails.formatted_address,
                landmark: placeDetails.address_components?.find(component => component.types?.includes("sublocality"))?.long_name || '',
                city: placeDetails.address_components?.find(component => component.types?.includes("locality"))?.long_name || ''
            }));
            setErrors({})
            setSuggestions([]);
        } catch (error) {
            console.error('Error fetching place details:', error);
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    };


    const handleDeliveryValidation = () => {
        const newErrors = validateFields(deliveryData);
        setErrors(newErrors);

        const hasErrors = Object.values(newErrors).some(error => error !== '');
        if (!hasErrors) {
            const deliveryInfo = `${deliveryData.houseFlatNo}, ${deliveryData.landmark}, ${deliveryData.address}, ${deliveryData.city}`;
            // Proceed with submission if there are no errors
            let userData = JSON.parse(localStorage.getItem('userData'));
            userData.completeAddress = deliveryInfo;
            userData.houseFlatNo = deliveryData.houseFlatNo;
            userData.landmark = deliveryData.landmark;
            userData.address = deliveryData.address;
            userData.city = deliveryData.city;
            localStorage.setItem('userData', JSON.stringify(userData));
            handleSubmit();
        }
    };

    useEffect(() => {
        const getUserAddressInfo = JSON.parse(localStorage.getItem('userData'));
        setDeliveryData({
            houseFlatNo: getUserAddressInfo?.houseFlatNo || '',
            landmark: getUserAddressInfo?.landmark || '',
            address: getUserAddressInfo?.address || '',
            city: getUserAddressInfo?.city || ''
        })
    }, [])


    return (
        <>
            <Drawer
                anchor="bottom"
                open={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)} // Close drawer on backdrop click
                PaperProps={{
                    className: "custom-drawer",
                }}
            >
                <Box className="drawer-box">
                    <div className='drawer-title-box'>
                        <img src='shipped.png' />
                        <Typography variant="h6" className="heading">
                            Delivery Information
                        </Typography>
                    </div>

                {process.env.REACT_APP_IS_GOOGLEMAP_ENABLED == "true" && 
                <>
                    <Autocomplete
                        freeSolo
                        options={suggestions.map((option) => option.description)} // Show description to user
                        renderInput={(params) => (
                            <div className='search-location'>
                            <TextField
                                {...params}
                                label="Search Your Location"
                                variant="standard"
                                placeholder="Search for places..."
                            />
                           {!isLoading && isCircularLoading && <CircularProgress color="success" size={30} className='circular-prog-center' />}
                            </div>
                        )}
                        onInputChange={(event, newValue) => handleAutoComplete(event)} // Handles input change
                        onChange={(event, value) => {
                            // Find the selected suggestion
                            const selectedSuggestion = suggestions.find(suggestion => suggestion.description === value);
                            if (selectedSuggestion) {
                                handleSuggestionSelect(selectedSuggestion); // Fetch place details and fill the form
                            }
                        }}
                    />
                    <br />
                    </>
                }

                    {/* <div>
                        <div></div> */}
                    {/* <div>OR</div> */}
                    {/* <div></div>
                    </div> */}
                    <Typography className='drawer-address-line' variant="body1" gutterBottom>
                        Please confirm your delivery details before proceeding.
                    </Typography>
                    {isLoading && <LinearProgress color="success"/>}
                    <div className='house-landmark'>
                        <div className='delivery-input-fields'>
                            <TextField
                                fullWidth
                                id="houseFlatNo"
                                type="text"
                                margin="normal"
                                variant="standard"
                                label="House/Flat No."
                                name='houseFlatNo'
                                maxLength={10}
                                value={deliveryData.houseFlatNo}
                                error={!!errors.houseFlatNoError}
                                helperText={errors.houseFlatNoError}
                                onChange={(e) => handleInputChange(e)}
                                inputProps={{
                                    autoComplete: "off",
                                    maxLength: 10
                                }}
                            />
                        </div>
                        <div className='delivery-landmark-field'>
                            <TextField
                                fullWidth
                                id="landmark"
                                type="text"
                                margin="normal"
                                variant="standard"
                                name='landmark'
                                label="Enter Landmark"
                                value={deliveryData.landmark}
                                error={!!errors.landmarkError}
                                helperText={errors.landmarkError}
                                onChange={handleInputChange}
                                inputProps={{
                                    autoComplete: "off",
                                    maxLength: 50
                                }}
                            />
                        </div>
                    </div>
                    {/* {isLoaded &&
                        <StandaloneSearchBox
                            onLoad={(ref) => (inputref.current = ref)}
                            onPlacesChanged={handleOnPlacesChanged}
                        > */}
                    <TextField
                        fullWidth
                        id="address-input"
                        type="text"
                        margin="normal"
                        name='address'
                        variant="standard"
                        label="Enter Your Current Address"
                        className="delivery-input-fields" value={deliveryData.address}
                        error={!!errors.addressError}
                        helperText={errors.addressError}
                        onChange={handleInputChange}
                        inputProps={{
                            autoComplete: "off",
                            placeholder: "Search for places...",
                            maxLength: 150
                            // Add other input properties as necessary
                        }}
                    />
                    {/* </StandaloneSearchBox>
                    } */}
                    <TextField
                        fullWidth
                        id="fullWidth"
                        type="text"
                        margin="normal"
                        variant="standard"
                        name="city"
                        label="City"
                        value={deliveryData.city}
                        error={!!errors.cityError}
                        helperText={errors.cityError}
                        onChange={handleInputChange}
                        className="delivery-input-fields"
                        // value={}
                        // onChange={}
                        inputProps={{
                            autoComplete: "off",
                            maxLength: 50
                        }}
                    />

                    <Button
                        variant="contained"
                        className="drawer-button"
                        onClick={() => {
                            handleDeliveryValidation();
                        }}
                    >
                        Confirm and Proceed
                    </Button>
                </Box>
            </Drawer>
        </>
    );
}
