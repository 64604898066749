import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Skeleton, Chip } from "@mui/material";
import DialogComp from "../ModalComp/DialogComp";
import dummy from "../../assets/DummyImage.png";
import "./RecommendedItems.css"; // Import the CSS file

const RecommendedItems = ({ items, addProductToCart, isLoading }) => {
    const getStoreData = useSelector((state) => state.store.storeData);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogItem, setDialogItem] = useState([]);

    const isStoreOpen = (timings) => {
        const now = new Date();
        const currentDay = now.toLocaleString("en-US", { weekday: "long" }).toLowerCase();
        const currentTime = now.toTimeString().slice(0, 8);

        const getPriviousDay = (dayIndex) => {
            return dayIndex === 0 ? timings.length - 1 : dayIndex - 1;
        };

        const currentDayIndex = timings.findIndex((timing) => timing.day === currentDay);

        if (currentDayIndex === -1) {
            return false;
        }

        for (const timing of timings) {
            if (timing.day === currentDay) {
                for (const slot of timing.slots) {
                    const startTime = slot.start_time.slice(0, 8);
                    const endTime = slot.end_time.slice(0, 8);

                    if (startTime < endTime) {
                        if (currentTime >= startTime && currentTime < endTime) {
                            return true;
                        }
                    } else {
                        const previousDayIndex = getPriviousDay(currentDayIndex);
                        const previousDayTimings = timings[previousDayIndex];

                        for (const slot of previousDayTimings.slots) {
                            const previousDayEndTime = slot.end_time.slice(0, 8);

                            if (currentTime >= startTime) {
                                return true;
                            }

                            if (currentTime < previousDayEndTime) {
                                return true;
                            } else {
                                return false;
                            }
                        }
                    }
                }
            }
        }

        return false;
    };

    const renderSkeletons = () =>
        Array.from({ length: 5 }).map((_, index) => (
            <div key={index} className="recommended-skeleton-card">
                <Skeleton
                    variant="rectangular"
                    width="100%"
                    height={200}
                    sx={{ bgcolor: "#d3d3d3" }}
                />
                <Skeleton variant="text" width="70%" sx={{ bgcolor: "#d3d3d3" }} />
                <Skeleton variant="text" width="80%" sx={{ bgcolor: "#d3d3d3" }} />
            </div>
        ));

    if (isLoading) {
        return (
            <div>
                <h2 className="featured-title divider line one-line">
                    <span>FEATURED ITEMS</span>
                </h2>
                <div className="featured-scroller">{renderSkeletons()}</div>
            </div>
        );
    }

    return (
        <div>
            <h2 className="featured-title divider line one-line">
                <span>FEATURED ITEMS</span>
            </h2>
            <div className="featured-scroller">
                {items.map((item) => (
                    <div key={item.refId} className="featured-card">
                        <div className="image-container">
                            <img
                                src={
                                    item.imgUrl && item.imgUrl !== null && item.imgUrl !== ""
                                        ? item.imgUrl
                                        : dummy
                                }
                                onError={(e) => {
                                    e.target.src = dummy;
                                }}
                                alt={item.title}
                                className="recommendedproduct-image"
                            />
                            {/* {item.customizable && (
                                <span className="customizable-tag">Customizable</span>
                            )} */}
                        </div>
                        <div className="product-info">
                            <p className="product-name">{item.title}</p>
                            <div className="p-b-setup">
                                <div>
                                    <p className="product-price">
                                        {getStoreData?.currencysymbol}{" "}
                                        {item.price
                                            ? item.price
                                            : item?.optionGroups.find(
                                                  (el) => el.item_ref_ids[0] == item.refId
                                              )?.options[0].price}
                                    </p>
                                </div>
                                <div>
                                    {item.available != 1 || item.available != true ? (
                                        <Chip
                                            label="Not available"
                                            color="error"
                                            variant="outlined"
                                            size="small"
                                        />
                                    ) : (
                                        Object.keys(getStoreData).length > 0 &&
                                        (getStoreData.active == "true" ||
                                            getStoreData.active == true) &&
                                        isStoreOpen(getStoreData.timings) && (
                                            <button
                                                className="add-to-cart-btn"
                                                onClick={() =>
                                                    addProductToCart(
                                                        item,
                                                        item.price
                                                            ? item.price
                                                            : item?.optionGroups.find(
                                                                  (el) =>
                                                                      el.item_ref_ids[0] ==
                                                                      item.refId
                                                              )?.options[0].price
                                                    )
                                                }
                                            >
                                                +
                                            </button>
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {openDialog && (
                <DialogComp
                    openDialog={openDialog}
                    setOpenDialog={setOpenDialog}
                    dialogItem={dialogItem}
                    addProductToCart={addProductToCart}
                />
            )}
        </div>
    );
};

export default RecommendedItems;
