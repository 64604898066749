import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./PaymentFailedPage.css";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import animationData from "../assets/payment-failed.lottie";
import Header from "../component/Header/Header";
import { Box, Container, Grid, Typography, Paper, Button } from "@mui/material";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import Pfe from "../assets/pfe.svg";

function PaymentFailedPage() {
    const navigate = useNavigate();
    // const location = useLocation();
    // const uniqueKey = location.state.uniqueKey;

    const handleMenuClick = () => {
        navigate("/catalogue");
    }

    const offerData = {
        offername: "",
        offerdescription: "",
        isofferapplied: false
    }

    localStorage.setItem('offerData', JSON.stringify(offerData));

    // useEffect(() => {
    //     if (!uniqueKey) {
    //         navigate("/catalogue");
    //     }
    // }, [uniqueKey]);

    return (
        <div>
            <Header />
            <Container className="payment-root">
                <Paper elevation={3} className="pf-order-error-container">
                    <Box className="pf-icon-container">
                        {/* <ErrorIcon className="pf-error-icon" /> */}
                        {/* <img src={Pfe} alt="Logo" className="set-snr-img" /> */}
                        <DotLottieReact src={animationData} loop autoplay />
                    </Box>
                    <Typography variant="h5" className="pf-title">
                        We Couldn't Place Your Order!
                    </Typography>
                    <Typography variant="body1" className="pf-order-id">
                        {/* Order ID: <span>{uniqueKey}</span> */}
                    </Typography>
                    <div className="pf-button-block">
                        <Button
                            variant="contained"
                            color="success"
                            className="pf-go-to-menu-button"
                            onClick={handleMenuClick}
                            startIcon={<MenuBookIcon />}
                        >
                            Go to Menu Page
                        </Button>
                    </div>
                </Paper>
            </Container>
        </div>
    );
}

export default PaymentFailedPage;
