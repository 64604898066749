import React from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../component/Footer/Footer";
import Logo from "../assets/company-logo.png";
import BackIcon from "../assets/left.png";
import "./TermsAndCondition.css";

function TermsAndCondition() {
    const navigate = useNavigate();
    const handleBack = () => {
        navigate("/catalogue");
    };

    return (
        <div>
            <div className="terms-container">
                <div className="static-page-back">
                    <img src={BackIcon} alt="Back" onClick={handleBack} />
                </div>
                <div className="logo-container">
                    <img src={Logo} alt="Company Logo" className="company-logo" />
                </div>
                <h1 className="terms-title">Terms and Conditions</h1>
                <ol className="terms-list">
                    <li>
                        <strong>Ownership:</strong> The website http://order.lamilano.pizza/ (the
                        “Website”) is owned and operated by La Milano Pizza (the “Company”, “Us” or
                        “We”). This Terms and Conditions of Use (the “Terms of Use”) of the Website
                        is entered into between the Company and any viewer or user of the Website
                        (referred to as “You”, “Yourself” or “User”). This Terms of Use provides the
                        manner in which the Company grants You permission to access, use, avail Our
                        Products/Offerings via Website/phone numbers available on the Website.
                        Should you not agree to the Terms of Use/ Privacy Policy or any other
                        policies, You may not access the Platform. We have just built the platform
                        to enable you and our food partners to fight COVID effect where you and the
                        food partner you order are solely responsibile for everything. MFW Foods Private Limited
                        disclaims any liability or losses occured to you or food partner due to any
                        3rd party services or our platform.
                    </li>
                    <li>
                        <strong>Personal Information:</strong> We may receive Your personal
                        information including name, phone number, e-mail address, address, credit
                        card information, bank information, etc.
                    </li>
                    <li>
                        <strong>Account Creation:</strong> In order to avail of the Services, You
                        will be required to register Yourself on the Platform and create an account
                        (“Account”). You will be responsible for maintaining the confidentiality of
                        the Account information. Further, You shall be responsible for all
                        activities through Your Account.
                    </li>
                    <li>
                        <strong>Delivery Time:</strong> The delivery time quoted to You upon order
                        confirmation is an approximate estimation and may vary.
                    </li>
                    <li>
                        <strong>Service Availability:</strong> The Services are available in respect
                        of the items on the menu available on the Platform at
                        http://thechocolateroomindia.com/ (“Item/s”). You agree and acknowledge that
                        provision of the Services is subject to the availability of the Item You
                        order, the delivery address, etc and other crucial factors. We will keep You
                        informed of any changes in the order at the time of confirming your order.
                    </li>
                    <li>
                        <strong>User Representations and Warranties:</strong> You represent and
                        warrant that (i) You are not incompetent/ barred under the applicable laws
                        to enter into this agreement; (ii) all the information provided to us are
                        correct and accurate; (iii) You shall not impersonate any person or
                        misrepresent Your identity or affiliation with another person; and (iv) The
                        Services availed are not for resale or for any illegal benefits. If You are
                        a minor and hence not competent under law to place orders, Your parents/
                        guardians may place the order on Your behalf. In such an event, Your
                        parents/ guardians shall be liable for any breach of the Terms of Use.
                    </li>
                    <li>
                        <strong>Authorization and Liability:</strong> If You are using the Platform
                        on behalf of any person/ entity, You represent and warrant that You are
                        authorized to accept these Terms of Use on behalf of such person/ entity.
                        Further, You and such person/ entity agree to be jointly, severally liable
                        and indemnify Us for violations of these Terms of Use.
                    </li>
                    <li>
                        <strong>Order Confirmation and Cancellation Policy:</strong> We will contact
                        on the phone number/ e-mail id provided to Us upon You placing an order to
                        confirm the details of the order, the price paid/ to be paid and estimated
                        delivery time. You shall not be entitled to cancel the order once You have
                        received the above confirmation from Us. If an Item You ordered is
                        unavailable post confirmation of the order, We will inform You of such
                        unavailability. In which case: i. If You have made the payment: You will not
                        be entitled to cancel the order and refund of the full amount paid for the
                        Services depending upon our partners approval. ii. There is no option for
                        Cash On Delivery or partly paid orders.
                    </li>
                    <li>
                        <strong>Delivery Responsibility and Payment:</strong> Failure to deliver for
                        no fault of Us but due to the reasons like lack of
                        information/authorization, then such Items shall be deemed to have been duly
                        delivered. In such an event, You shall make payment for the same.
                    </li>
                    <li>
                        <strong>Item Rejection and Payment Liability:</strong> You may reject the
                        Item and not be liable to make the payment, if: i. You have received the
                        Item/ packaging in a damaged state or in a state not fit for human
                        consumption, or ii. You have not placed the order.
                    </li>
                    <li>
                        <strong>Intellectual Property Rights:</strong> We are the owner or the
                        authorized user of all the intellectual property rights in the Platform and
                        in the material published on it. All the works on the Platform are protected
                        by copyright, patent and trademark laws and various other intellectual
                        property rights. All such rights are hereby reserved. Any act including but
                        not limited to modification, publication, passing off, exploitation, attempt
                        to take any unfair advantage of the intellectual property rights in the
                        Platform shall amount to infringement of Our intellectual property rights.
                    </li>
                    <li>
                        <strong>Usage Restrictions and Agreement:</strong> You shall not reverse
                        engineer, modify, copy, distribute, transmit, display, perform, reproduce,
                        publish, license, create derivative works from, transfer, or sell any
                        information, material, software, products or services from the Platform.
                        Your use of the Platform constitutes Your agreement and acceptance to the
                        terms and conditions set forth therein. In addition, as a condition of Your
                        use of the Platform, You represent and warrant that You will not use the
                        Platform for any purpose that is unlawful, immoral or prohibited under the
                        applicable laws of India. If You do not agree and accept the terms and
                        conditions set forth herein, do not use the Platform. Other than this
                        agreement, We will not enter into any agreement with You through the
                        Platform, and no attempt to create such an agreement or obligation will be
                        effective.
                    </li>
                    <li>
                        <strong>Prohibited Activities:</strong> You shall not engage in any activity
                        that interferes with or disrupts access to the Platform or the Services.
                    </li>
                    <li>
                        <strong>Disclaimer of Warranties:</strong> The Platform, Services and other
                        materials are provided by Us on an “as is” basis without warranty of any
                        kind, express, implied, statutory or otherwise, including the implied
                        warranties of title, non-infringement, merchantability or fitness for a
                        particular purpose. Without limiting the foregoing, We make no warranty that
                        (i) the Platform or the Services will meet Your requirements or Your use of
                        the Platform or the Services will be uninterrupted, timely, secure or
                        error-free; (ii) the results that may be obtained from the use of the
                        Platform or Services will be effective, accurate or reliable; (iii) the
                        quality of the Platform or Services will meet your expectations, or that
                        (iv) any errors or defects in the Platform or Services will be corrected. No
                        advice or information, whether oral or written, obtained by You from Us or
                        through or from use of the Platform shall create any warranty not expressly
                        stated in the Terms of Use.
                    </li>
                    <li>
                        <strong>Limitation of Liability:</strong> In no event shall We, Our
                        officers, directors, employees, or agents be liable for any direct,
                        indirect, incidental, punitive or consequential damage, injury of any kind
                        in connection with the Services, Platform, Terms of Use, Privacy Policy or
                        any other policy of the Company.
                    </li>
                    <li>
                        <strong>Conflict of Terms:</strong> In the event there is any conflict or
                        inconsistency between the Terms of Use and any other policy, terms of use
                        that appear on the Platform, the Terms of Use will prevail.
                    </li>
                    <li>
                        <strong>Governing Law and Jurisdiction:</strong> The Terms of Use are
                        governed by and construed in accordance with the laws of India. You agree to
                        submit to the exclusive jurisdiction of the courts.
                    </li>
                    <li>
                        <strong>Modification of Terms:</strong> We reserve the right to change or
                        modify the Terms of Use or any policy on the Platform including the Privacy
                        Policy, at any time and in our sole discretion. Any changes or modifications
                        will be effective immediately upon posting the revisions on the Platform.
                        Your continued use of the Platform confirms Your acceptance of such
                        revisions. Therefore, You may frequently review the Terms of Use and
                        applicable policies to understand the terms and conditions that apply to
                        Your use of the Platform. If You do not agree to the amended terms, You must
                        stop using the Platform. We may discontinue, with or without reason, access
                        to the Platform, the Services (or any part thereof) at any time at Our
                        discretion.
                    </li>
                    <li>
                        <strong>Communication Consent:</strong> You hereby expressly agree to
                        receive communications by way of SMS, calls, e-mails from Us relating to
                        Services provided by Us. You can unsubscribe/ opt-out from receiving such
                        communications anytime by writing us through contact us form on
                        lamilano.pizza website.
                    </li>
                    <li>
                        <strong>Payment Methods:</strong> We accept payment through Cards, Wallets,
                        Netbanking etc. for our online transactions
                    </li>
                    <li>
                        <strong>Refund Liability:</strong> MFW Foods Private Limited not liable for any delay in refund
                        due to 3rd party services. We also are not directly answerable to end user
                        as our food partner will be responsible to take care for their services and
                        your financial transaction.
                    </li>
                    {/* Add more terms as needed */}
                </ol>
            </div>
            <Footer />
        </div>
    );
}

export default TermsAndCondition;
