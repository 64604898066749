export const clearLocalStorage = () => {
    localStorage.removeItem("bopisToken");
    localStorage.removeItem("cartData");
    localStorage.removeItem("locationData");
    localStorage.removeItem("menuData");
    localStorage.removeItem("offerData");
    localStorage.removeItem("tokenData");
    localStorage.removeItem("userData");
    localStorage.removeItem("companyId");
    localStorage.removeItem("isLocationAvailable");
    localStorage.removeItem("recommended");
    localStorage.removeItem("tblid");
    localStorage.removeItem("tblname");
};
