import React, { useContext, useEffect, useState } from 'react';
import Header from '../component/Header/Header';
import {
    Card,
    Divider,
    Grid,
    ListItemText,
    Typography,
    Stepper,
    Step,
    StepLabel,
    StepConnector,
    Paper,
    SvgIcon,
    Button,
} from '@mui/material';
import StoreIcon from '@mui/icons-material/Store';
import CallIcon from '@mui/icons-material/Call';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import './OrderDetails.css';
import axiosApiFunction from '../utils/axiosapifunc';
import { format, parse } from 'date-fns';
import { useNavigate, useParams } from 'react-router-dom';
import ErrorContext from '../context/ErrorContext';
import Spinner from '../component/Spinner/Spinner';
import HistoryIcon from '@mui/icons-material/History';
import BackIcon from "../assets/left.png";


const OrderDetails = () => {
    // const currentOrderStatus = 2; // Simulated order status (0: Received, 1: Accepted, 2: Prepared, 3: Served)
    let curr_code;
    const allSteps = ["Placed", "Accepted", "Food Ready", "Dispatched", "Done"];
    const [steps, setSteps] = useState(allSteps);
    const [stepDates, setStepDates] = useState([]);
    const [currentOrderStatus, setCurrentOrderStatus] = useState(0);
    // const stepperDates = ["2024-10-10", "2024-10-10", "2024-10-10", ""];
    // const steppertimes = ["01:45 PM", "01:47 PM", "01:47 PM", ""];

    const [data, setData] = useState([]);
    const [statusData, setStatusData] = useState([]);
    const [riderData, setRiderData] = useState({});
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const { apiError, setApiError, clearApiError } = useContext(ErrorContext);

    const navigate = useNavigate();
    const { orderid } = useParams();
    const token = JSON.parse(localStorage.getItem('tokenData')).token;
    const locationData = JSON.parse(localStorage.getItem('locationData'));

    // useEffect(() => {
    //     const getOrderDetails = async () => {
    //         const response = await axiosApiFunction('GET', '/api/v1/getorderdetails', { locationid: '5', orderid }, token);
    //         if (response.data.success == 0) {
    //             setError('Order Not Found')
    //         } else {
    //             setData(response.data.orderdata);
    //         }
    //     }
    //     getOrderDetails();
    // }, [])

    const CustomStepIcon = (props) => {
        const { active, completed, icon, index } = props; // use index to determine current step
        const iconColor = steps[index] == "Cancelled" ? 'red' : (completed || active ? 'green' : 'grey')
        return (
            <SvgIcon sx={{ color: iconColor }}>
                {steps[index] == "Cancelled" ? <CancelIcon /> : <CheckCircleOutlineIcon />}
            </SvgIcon>
        );
    };

    const getCurrentOrderStatus = (orderState) => {
        // orderState = "Cancelled";
        if (orderState == "Cancelled") {
            setSteps(["Placed", "Cancelled"])
            return allSteps.length - 1;
        }
        return steps.indexOf(orderState);
    };

    const handleLocationClick = (data) => {
        const googleMapsUrl = `https://www.google.com/maps/dir//${Number(
            data?.order?.store?.latitude
        )},${Number(data?.order?.store?.longitude)}`;
        window.open(googleMapsUrl, "_blank");
    };

    const handleDialPadOpen = (phone) => {
        // Use `tel:` link to open the dial pad
        window.location.href = `tel:${phone}`;
    };

    const setOrderDate = (timestamp) => {
        // Convert timestamp to Date object
        const date = new Date(timestamp * 1000); // Multiply by 1000 to convert to milliseconds

        const formattedDateTime = date.toLocaleString("en-GB");

        return formattedDateTime;
    };

    useEffect(() => {
        const getOrderDetails = async () => {
            try {
                setIsLoading(true);
                clearApiError(); // Clear any previous API error
    
                const response = await axiosApiFunction('GET', '/api/v1/getorderdetails', { locationid: locationData.locationid, orderid }, token);
    
                if (response.data.success === 0) {
                    setError(response.data?.message);
                } else {
                    let orderData = response.data.orderdata;
                    let orderStatus = response.data.orderstatustiming;

                    setData(orderData);
                    setStatusData(orderStatus);
                    if (orderStatus.length > 0) {
                        const riderData = orderStatus.find((status) => status.riderdetails !== null);
                        if(riderData) setRiderData(riderData.riderdetails);
                    }

                    let statusDateList = [];
                    for (let i = 0; i < allSteps.length; i++) {
                        if(orderStatus[i]?.createddate){
                            statusDateList.push(orderStatus[i]?.createddate);
                        } else{
                            statusDateList.push("");
                        }
                    }
                    setStepDates(statusDateList);

                    const stepIndex = getCurrentOrderStatus(orderStatus[orderStatus.length - 1]?.newState || "Placed");
                    setCurrentOrderStatus(stepIndex);
    
                    // Safely add static data to options_to_add
                    if (orderData && orderData.order && orderData.order.items) {
                        const items = orderData.order.items;
    
                        items.forEach(item => {
                            // Check if options_to_add is an array, initialize if it's not
                            if (!Array.isArray(item.options_to_add)) {
                                item.options_to_add = [];
                            }
                        });
                    }
                }
            } catch (error) {
                setApiError(error.message);
                // navigate('/server-not-reachable');
                setApiError(`Error: Received status ${error.message}`);
            } finally {
                setIsLoading(false);  // Ensure loading is set to false after the request
            }
        };
    
        getOrderDetails();
    }, []);  // Add necessary dependencies

    const handleBack = () => {
        navigate('/order-history');
      }
    

    curr_code = data.order?.store?.currencysymbol;

    return (
        <div>
            <Header />
            {isLoading ? <Spinner enabled={isLoading} /> :
                <>
                    {/* {apiError && <span style={{ color: 'red' }}>{apiError}<br /></span>} */}
                        <Grid container justifyContent="center" className="center-column">
                            <Grid item xs={12} md={8} lg={6} className="center-column">
                                <Paper className="od-card-style" elevation={3} style={{ padding: '30px', margin: '20px auto' }}>
                                        <div className="backpart od-backbutton">
                                            <div className="backiconset">
                                                <img src={BackIcon} alt="Back" onClick={handleBack} />
                                            </div>
                                            <div className="brandnameback">
                                                <h3>Back</h3>
                                                {/* <div className='up-historyicon'><HistoryIcon /></div> */}
                                            </div>
                                        </div>
                                {data.order ? (
                                    <>
                                        {/* <div className='do-historyicon' onClick={handleHistoryNavigate}><HistoryIcon/></div> */}
                                        <ListItemText
                                            primary={
                                                <Typography className="order-title" style={{ fontWeight: 'bold', color: '#333' }}>
                                                    Order #{data.order.details.id}
                                                </Typography>
                                            }
                                            secondary={
                                                <React.Fragment>
                                                    <Divider style={{ margin: '15px 0', backgroundColor: '#ddd' }} />
                                                    <div className="order-details-container">
                                                        <div className="od-row">
                                                            <div className="od-left-section" onClick={() => handleLocationClick(data)}
                                                                style={{ cursor: 'pointer' }}>
                                                                <StoreIcon className='icon-color' />
                                                                <div>
                                                                    <Typography variant="subtitle1" style={{ color: '#555' }}>
                                                                        {data.order.store?.name}
                                                                    </Typography>
                                                                    <Typography variant="body2" style={{ color: '#888' }}>
                                                                        {data.order.store?.address}
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                            <div className="od-right-section" onClick={() => handleDialPadOpen(data.order.store?.contact_phone)}
                                                                style={{ cursor: 'pointer' }}>
                                                                    <CallIcon className='icon-color'/>
                                                                    <div>
                                                                        <Typography variant="subtitle1" style={{ color: '#555' }}>
                                                                            Mobile Number
                                                                        </Typography>
                                                                        <Typography variant="body2" style={{ color: '#888' }}>
                                                                            {data.order.store?.contact_phone}
                                                                        </Typography>
                                                                    </div>
                                                            </div>
                                                        </div>
                                                    
                                                    <div className="od-row">
                                                        <div className="od-left-section">
                                                            <LocalDiningIcon className='icon-color' />
                                                            <div>
                                                                <Typography variant="subtitle1" style={{ color: '#555' }}>
                                                                    Order Type
                                                                </Typography>
                                                                <Typography variant="body2" style={{ color: '#888' }}>
                                                                    {data.order.details.order_type}
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                        <div className="od-right-section">
                                                            <AccountBalanceWalletIcon className='icon-color' />
                                                            <div>
                                                                <Typography variant="subtitle1" style={{ color: '#555' }}>
                                                                    Payment Method
                                                                </Typography>
                                                                <Typography variant="body2" style={{ color: '#888' }}>
                                                                    {(data.order?.payment[0]?.option).toUpperCase()}
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>

                                                    <Divider style={{ margin: '15px 0', backgroundColor: '#ddd' }} />
                                                    <Grid className='order-details-block'>
                                                        <Typography  className="order-title" style={{ fontWeight: 'bold', color: '#333' }}>
                                                            Order Details
                                                        </Typography>
                                                        <div className='flex-between' style={{ margin: '10px 0' }}>
                                                            <div>Date and Time</div>
                                                            <div> {setOrderDate(data.order.details.created)}</div>
                                                        </div>
                                                        {data.order.details?.tableid > 0 && (
                                                            <div className='flex-between' style={{ margin: '10px 0' }}>
                                                                <div>Table No.</div>
                                                                <div>{data.order.details?.tablename}</div>
                                                            </div>
                                                        )}
                                                        {/* <div className='flex-between' style={{ margin: '10px 0' }}>
                                                <div>Payment Gateway</div>
                                                <div>Stripe</div>
                                            </div> */}
                                                    </Grid>
                                                    <Stepper
                                                        alternativeLabel
                                                        activeStep={currentOrderStatus}
                                                        connector={<StepConnector className="stepper-connector" />}
                                                        className="stepper-styles"
                                                    >
                                                        {steps.map((label, index) => (
                                                            <Step key={label} completed={currentOrderStatus >= index}>
                                                                <StepLabel StepIconComponent={(props) => <CustomStepIcon {...props} index={index} />}>
                                                                    <div className='list-date-txtlight'>
                                                                        <span>{label}</span>
                                                                    </div>
                                                                    <div className='list-date-txtlight'>
                                                                        <span>{stepDates[index - 1] ? stepDates[index - 1].split(' ')[0] : ''}</span>
                                                                    </div>
                                                                    <div className='list-date-txtlight'>
                                                                        <span className="time-light">{stepDates[index - 1] ? stepDates[index - 1].split(' ')[1] : ''}</span>
                                                                    </div>
                                                                </StepLabel>
                                                            </Step>
                                                        ))}

                                                    </Stepper>

                                                    <Divider style={{ margin: '15px 0', backgroundColor: '#ddd' }} />
                                                    <Grid>
                                                        <Typography  className="order-title" style={{ fontWeight: 'bold', color: '#333' }}>
                                                            Item Details
                                                        </Typography>
                                                        {data.order.items?.length > 0 && data.order.items.map((item, index) => (
                                                            <Grid key={index} sx={{ my: 1.5 }}>
                                                                <div className='flex-center'>
                                                                    <div className="veg-flag"><span></span></div>
                                                                    <div className='od-addons-style'>
                                                                        {item.title}
                                                                        {item.options_to_add.length > 0 && (
                                                                            <div style={{ fontSize: '14px', color: '#555' }}>
                                                                                Variation: {item.options_to_add
                                                                                    .filter(option => option.group.merchant_id == item.merchant_id) // Filter based on matching merchant_id
                                                                                    .map((option, idx) => (
                                                                                        <span key={idx}>
                                                                                            {option.title} ({curr_code} {option.price}){idx < item.options_to_add.length - 1 ? ', ' : ''}
                                                                                        </span>
                                                                                    ))}
                                                                            </div>
                                                                        )}

                                                                        {item.options_to_add.length > 0 && (
                                                                            <div style={{ fontSize: '14px', color: '#555' }}>
                                                                                Add-ons: {item.options_to_add
                                                                                    .filter(option => option.group.merchant_id != item.merchant_id) // Filter for options not equal to merchant_id
                                                                                    .map((option, idx) => (
                                                                                        <span key={idx}>
                                                                                            {option.title} ({curr_code} {option.price}){idx < item.options_to_add.length - 1 ? ', ' : ''}
                                                                                        </span>
                                                                                    ))}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                <div className='flex-between'>
                                                                    {/* Display base price and variant prices together */}
                                                                    {(item.price == 0 && item.options_to_add?.length > 0) ? (
                                                                        item.options_to_add
                                                                            .filter(option => option.group.merchant_id == item.merchant_id) // Filter for matching merchant_id
                                                                            .map((option, idx) => (
                                                                                <div key={idx} className='qty-price'>{curr_code} {option.price} X {item.quantity}</div>
                                                                            ))
                                                                    ) : (
                                                                        <div className='qty-price'>{curr_code} {item.price} X {item.quantity}</div>
                                                                    )}

                                                                    {/* Add the item price + variant prices, multiplied by quantity */}
                                                                    <div>{curr_code} {(item.price + item?.options_to_add.reduce((acc, option) => acc + option.price, 0)) * item.quantity}</div>
                                                                </div>
                                                            </Grid>
                                                        ))}

                                                    </Grid>

                                                    <Divider style={{ margin: '15px 0', backgroundColor: '#ddd' }} />
                                                    <div className='flex-between' style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                        <div>Subtotal</div>
                                                        <div>{curr_code} {parseFloat((data.order.details.order_subtotal).toFixed(2))}</div>
                                                    </div>
                                                    {data.order.details.discount > 0 && (
                                                        <div className='flex-between' style={{ fontWeight: 'bold', fontSize: '16px', color: 'var(--links)' }}>
                                                            <div>Discount</div>
                                                            <div> - {curr_code} {parseFloat((data.order.details.discount).toFixed(2))}</div>
                                                        </div>
                                                    )}
                                                    <div className='flex-between' style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                        <div>Total Tax</div>
                                                        <div>{curr_code} {parseFloat((data.order.details.total_taxes).toFixed(2))}</div>
                                                    </div>
                                                    <div className='flex-between' style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                        <div>Total Charges</div>
                                                        <div>{curr_code} {parseFloat((data.order.details.total_charges).toFixed(2))}</div>
                                                    </div>
                                                    <Divider style={{ margin: '15px 0', backgroundColor: '#ddd' }} />
                                                    <div className='flex-between' style={{ fontSize: '18px', fontWeight: 'bold', color: 'var(--links)' }}>
                                                        <div>Grand Total</div>
                                                        <div>{curr_code} {parseFloat((data.order.details.order_total).toFixed(2))}</div>
                                                    </div>
                                                    
                                                    {data.customer?.phone && (
                                                        <>
                                                            <Divider style={{ margin: '15px 0', backgroundColor: '#ddd' }} />
                                                            <Grid className='order-details-block'>
                                                                <Typography  className="order-title" style={{ fontWeight: 'bold', color: '#333', marginBottom: '10px' }}>
                                                                    Customer Details
                                                                </Typography>
                                                                <div className='flex-between'>
                                                                    <div>Customer Name</div>
                                                                    <div>{data.customer?.name}</div>
                                                                </div>
                                                                <div className='flex-between'>
                                                                    <div>Phone</div>
                                                                    <div>{data.customer?.phone}</div>
                                                                </div>
                                                                <div className='flex-between'>
                                                                    <div>Email</div>
                                                                    <div>{(data.customer.email != 'null') ? data.customer.email : "NA"}</div>
                                                                </div>
                                                                <div className='flex-between'>
                                                                    <div>Deliver To: </div>
                                                                    <div style={{ marginLeft: '100px' }}>{data.customer.address.sub_locality ? data.customer.address.sub_locality : "NA"}</div>
                                                                </div>
                                                            </Grid>
                                                        </>
                                                    )}

                                                    
                                                    {riderData.riderid > 0 && (
                                                        <>
                                                            <Divider style={{ margin: '15px 0', backgroundColor: '#ddd' }} />
                                                            <Grid className='order-details-block'>
                                                                <Typography  className="order-title" style={{ fontWeight: 'bold', color: '#333', marginBottom: '10px' }}>
                                                                    Rider Details
                                                                </Typography>
                                                                <div className='flex-between'>
                                                                    <div>Rider Name</div>
                                                                    <div>{riderData.ridername}</div>
                                                                </div>
                                                                <div className='flex-between'>
                                                                    <div>Phone</div>
                                                                    <div>{riderData.ridermobileno}</div>
                                                                </div>
                                                            </Grid>
                                                        </>
                                                    )}
                                                    {/* <Grid className='flex-between' mt={2}>
                                            <Grid><Button variant="outlined" color="success">Print</Button></Grid>
                                            <Grid><Button variant="outlined" color="success">Download</Button></Grid>
                                        </Grid> */}
                                                </React.Fragment>
                                            }
                                        />
                                    </>
                                ) : (
                                    <Typography>{error}</Typography>
                                )
                                }
                            </Paper>
                        </Grid>
                    </Grid>
                </>}

        </div>
    );
};

export default OrderDetails;
