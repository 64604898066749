import CryptoJS from "crypto-js";

export const decryptData = (encryptedData) => {
    const key = "ASdis83!8217o9ASdis83!8217o9ASdi";
    const encryptedBytes = CryptoJS.enc.Base64.parse(encryptedData); // Decode Base64 to bytes
    const ivLength = 16; // AES block size
    const iv = CryptoJS.lib.WordArray.create(encryptedBytes.words.slice(0, ivLength / 4)); // Extract IV
    const ciphertext = CryptoJS.lib.WordArray.create(encryptedBytes.words.slice(ivLength / 4));

    const decrypted = CryptoJS.AES.decrypt(
        { ciphertext },
        CryptoJS.enc.Utf8.parse(key), // Parse the key
        {
            iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        }
    );

    return decrypted.toString(CryptoJS.enc.Utf8); // Convert decrypted data to UTF-8 string
};

export const parseDecryptedData = (decryptedData) => {
    const params = new URLSearchParams(decryptedData);

    const lid = params.get("lid");
    const cid = params.get("cid");
    const tblid = params.get("tblid");
    const tblname = params.get("tblname");
    // return { lid, cid };
    return { lid, cid, tblid, tblname };
};
