import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import GetOtp from "../Auth/GetOtp";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import animationData from "../../assets/login-success.lottie";
import {
    Box,
    Card,
    CardContent,
    Divider,
    TextField,
    CircularProgress,
    Button,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import BuIcon from "../../assets/b-arrow.png";
import axiosApiFunction from "../../utils/axiosapifunc";
import ErrorContext from "../../context/ErrorContext";
import { login } from "../../redux/slice/AuthSlice";
import "./LoginAtCheckoutModal.css";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
    "& .MuiDialog-paper": {
        borderRadius: "20px",
        minWidth: "400px",

        "@media (min-width: 1024px) and (max-width: 1500px)": {
            // width: "60%",
            maxWidth: "380px",
        },

        "@media (min-width: 900px) and (max-width: 1024px)": {
            // width: "70%",
            maxWidth: "380px",
        },

        // Smaller screen settings for even tighter widths
        [theme.breakpoints.down("sm")]: {
            // width: "90%",
            minWidth: "70%",
        },
    },
}));

function LoginAtCheckoutModal({ openDialog, setOpenDialog, companyId, locationId, locationData }) {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [showOtpInput, setShowOtpInput] = useState(false);
    const [showSuccessAnimation, setShowSuccessAnimation] = useState(false);
    const [nameError, setNameError] = useState("");
    const [mobileError, setMobileError] = useState("");
    const [otpError, setOtpError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const submitButtonRef = useRef(null);
    const [isOTPEnabled, setIsOTPEnabled] = useState(locationData?.isotprequired || 0);
    const { apiError, setApiError, clearApiError } = useContext(ErrorContext);

    const [formData, setFormData] = useState({
        username: "",
        usermobile: "",
    });

    const [counter, setCounter] = useState(30);

    const handleInputChange = (e) => {
        setNameError("");
        setMobileError("");
        setOtpError("");
        const { name, value } = e.target;
        if (name === "usermobile") {
            if (/^\d*$/.test(value) && value.length <= 10) {
                setFormData((prevState) => ({ ...prevState, [name]: value }));
            }
        } else {
            setFormData((prevState) => ({ ...prevState, [name]: value }));
        }
    };
    const handleOtp = (otpValue) => {
        setFormData((prevState) => ({ ...prevState, otp: otpValue }));
        setOtpError("");
    };

    const validateInput = (data) => {
        let isValid = true;
        if (!/^[A-Za-z0-9 ]{3,50}$/.test(data.username)) {
            setNameError("Name should be atleast 3 to 50 characters long");
            isValid = false;
        } else {
            setNameError("");
        }

        if ((locationData?.countrycode === "IN")) {
            if (!/^[6-9]\d{9}$/.test(data.usermobile)) {
                setMobileError("Enter valid mobile number");
                isValid = false;
            } else {
                setMobileError("");
            }
        } else {
            if (!/^\d{9,10}$/.test(data.usermobile)) {
                setMobileError("Enter a valid mobile number");
                isValid = false;
            } else {
                setMobileError("");
            }
        }

        return isValid;
    };

    const handleDirectLogin = async (data) => {
        clearApiError();
        let isValid = true;
        if (!/^[A-Za-z0-9 ]{3,50}$/.test(data.username)) {
            setNameError("Name should be atleast 3 to 50 characters long");
            isValid = false;
        } else {
            setNameError("");
        }

        if ((locationData?.countrycode === "IN")) {
            if (!/^[6-9]\d{9}$/.test(data.usermobile)) {
                setMobileError("Enter valid mobile number");
                isValid = false;
            } else {
                setMobileError("");
            }
        } else {
            if (!/^\d{9,10}$/.test(data.usermobile)) {
                setMobileError("Enter a valid mobile number");
                isValid = false;
            } else {
                setMobileError("");
            }
        }

        if (!isValid) return;
        if (isLoading) return;
        setIsLoading(true);

        try {
            let fetchUserData = formData ? formData : JSON.parse(localStorage.getItem("userData"));

            const response = await axiosApiFunction("post", "/api/v1/login", {}, "", {
                companyid: companyId,
                mobileno: formData.usermobile,
                otp: fetchUserData.otp,
            });

            localStorage.setItem("tokenData", JSON.stringify(response.data));
            localStorage.setItem("userData", JSON.stringify(formData));
            dispatch(login());
            setOpenDialog(false);

            // navigate("/catalogue");
        } catch (error) {
            setApiError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const onOtpSendHandle = async () => {
        clearApiError();
        if (!validateInput(formData)) {
            return;
        }

        if (isLoading) return;

        setIsLoading(true);

        try {
            const response = await axiosApiFunction("POST", "/api/v1/sendotp", {
                phoneno: formData.usermobile,
                locationid: locationId,
                companyid: companyId,
            });
            if (response.data.success === 1) {
                setShowOtpInput(true);
                setCounter(30);
            } else {
                setApiError(response.data.msg);
            }
        } catch (error) {
            setApiError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmit = async () => {
        if (isLoading) return;
        setIsLoading(true);
        let fetchUserData = formData ? formData : JSON.parse(localStorage.getItem("userData"));

        if (formData.otp?.length < 6) {
            if (formData.otp?.length === 0) {
                setOtpError("Please Enter OTP");
            } else {
                setOtpError("Please Enter Correct OTP");
            }
            setIsLoading(false);
            return;
        } else if (!formData.otp) {
            setOtpError("Please Enter OTP");
            setIsLoading(false);
            return;
        }

        if (formData?.usermobile !== fetchUserData?.usermobile) {
            localStorage.removeItem("cartData");
        }
        localStorage.removeItem("userData");

        try {
            const response = await axiosApiFunction("post", "/api/v1/verifyotp", {
                phoneno: fetchUserData.usermobile,
                otp: fetchUserData.otp,
            });
            if (response.data.success === 1) {
                const tokenResp = await axiosApiFunction("post", "/api/v1/login", {}, "", {
                    companyid: companyId,
                    mobileno: formData.usermobile,
                    otp: fetchUserData.otp,
                });
                // console.log('tokenrespp>>>>>>>>', tokenResp);
                // if(tokenResp.data.data.phoneno === formData.usermobile){
                localStorage.setItem("tokenData", JSON.stringify(tokenResp.data));
                localStorage.setItem("userData", JSON.stringify(formData));
                dispatch(login());
                setShowSuccessAnimation(true); // Show success animation
                setTimeout(() => {
                    setOpenDialog(false); // Close modal after animation
                }, 2000);
                // navigate("/catalogue");
                // }
            } else {
                setOtpError(response.data.msg);
                setIsLoading(false);
            }
        } catch (error) {
            setApiError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setIsOTPEnabled(locationData?.isotprequired || 0);
    }, [locationData?.isotprequired]);

    useEffect(() => {
        let timer;
        if (showOtpInput && counter > 0) {
            timer = setInterval(() => {
                setCounter((prevCounter) => prevCounter - 1);
            }, 1000);
        } else if (counter === 0) {
            clearInterval(timer);
        }
        return () => clearInterval(timer);
    }, [showOtpInput, counter]);

    useEffect(() => {
        setOpen(openDialog);
        setShowOtpInput(false);
        setShowSuccessAnimation(false);

        if (openDialog) {
            setFormData({
                username: "",
                usermobile: "",
            });
            setOtpError("");
            setNameError("");
            setMobileError("");
        }
    }, [openDialog]);

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleWrongNumberClick = () => {
        setShowOtpInput(false); // Return to the initial input fields
    };

    const handleResendOtp = () => {
        onOtpSendHandle(); // Resend OTP
    };

    return (
        <React.Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={openDialog}
                className="model-backdrop"
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Login to Continue
                </DialogTitle>
                <Divider />
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <Box
                    sx={{
                        width: "100%",
                        maxWidth: {
                            xs: "85%", // Mobile
                            sm: "360px", // Tablets
                            md: "360px", // Laptops
                            lg: "360px", // Desktops
                        },
                        // maxWidth: 360,
                        overflowY: "auto",
                        overflowX: "hidden",
                        display: "flex",
                        flexDirection: "column",
                        padding: "20px",
                    }}
                >
                    {/* <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                            padding: "10px",
                        }}
                    >
                        <Card sx={{ boxShadow: 3 }}>
                            <CardContent>
                                <Typography>No Offers Available Yet</Typography>
                            </CardContent>
                        </Card>
                    </Box> */}
                    {showSuccessAnimation ? (
                        <Box textAlign="center">
                            <DotLottieReact src={animationData} loop={false} autoplay />
                            <Typography variant="h6" sx={{ mt: 2 }}>
                                Login Successful!
                            </Typography>
                        </Box>
                    ) : !showOtpInput ? (
                        <>
                            <TextField
                                // fullWidth
                                id="fullWidth"
                                type="text"
                                margin="normal"
                                placeholder="Enter Your Name"
                                name="username"
                                value={formData.username}
                                onChange={(e) => handleInputChange(e)}
                                sx={{
                                    border: "none",
                                    outline: "none",
                                    backgroundColor: "#f1f0f5",
                                    borderRadius: "10px",
                                    border: "1px solid #c3c3c3",
                                }}
                                inputProps={{
                                    autoComplete: "off",
                                }}
                                // className="store-search-input"
                            />
                            <span style={{ color: "red", fontSize: "0.8rem" }}>{nameError}</span>
                            <TextField
                                // fullWidth
                                id="fullWidth"
                                type="tel"
                                margin="normal"
                                placeholder="Enter 10 Digit Mobile Number"
                                name="usermobile"
                                inputMode="numeric"
                                sx={{
                                    border: "none",
                                    outline: "none",
                                    backgroundColor: "#f1f0f5",
                                    borderRadius: "10px",
                                    border: "1px solid #c3c3c3",
                                }}
                                inputProps={{
                                    maxLength: 10,
                                    autoComplete: "off",
                                }}
                                value={formData.usermobile}
                                onChange={(e) => handleInputChange(e)}
                                // className="store-search-input"
                            />
                            <span style={{ color: "red", fontSize: "0.8rem" }}>{mobileError}</span>

                            <br />
                            {/* {apiError && (
                                <span>
                                    {apiError}
                                    <br />
                                </span>
                            )} */}
                            <Button
                                sx={{
                                    borderRadius: 10,
                                    background: "#FF3A44",
                                }}
                                variant="contained"
                                color="success"
                                onClick={
                                    isOTPEnabled
                                        ? onOtpSendHandle
                                        : () => handleDirectLogin(formData)
                                }
                                className="login-button-text"
                            >
                                {!isLoading ? (
                                    <span className="login-button-icon">
                                        {isOTPEnabled ? "Send OTP" : "Submit"}{" "}
                                        <img src={BuIcon} alt="BuIcon" />
                                    </span>
                                ) : (
                                    <CircularProgress color="inherit" />
                                )}
                            </Button>
                        </>
                    ) : (
                        <div>
                            <Typography
                                padding={3}
                                textAlign={"center"}
                                className="login-modal-text"
                                sx={{
                                    fontWeight: 600,
                                }}
                            >
                                ENTER YOUR OTP
                            </Typography>
                            <GetOtp length={6} onOtpChange={handleOtp} submitButtonRef={submitButtonRef} />
                            <span style={{ color: "red" }}>{otpError}</span>
                            <br />
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column", // Stack the components vertically
                                    alignItems: "center", // Center only these components horizontally
                                }}
                            >
                                <Button
                                    ref={submitButtonRef}
                                    sx={{
                                        marginTop: 3,
                                        borderRadius: 10,
                                        background: "#FF3A44",
                                    }}
                                    variant="contained"
                                    color="success"
                                    onClick={handleSubmit}
                                >
                                    {!isLoading ? (
                                        <span className="login-button-icon">
                                            Submit <img src={BuIcon} />
                                        </span>
                                    ) : (
                                        <CircularProgress color="inherit" />
                                    )}
                                </Button>
                                <Typography
                                    variant="body2"
                                    color="primary"
                                    sx={{ marginTop: 2, cursor: "pointer" }}
                                    onClick={handleWrongNumberClick}
                                >
                                    Wrong number?
                                </Typography>
                                {counter > 0 ? (
                                    <Typography variant="body2" sx={{ marginTop: 2 }}>
                                        Resend OTP in: {counter}s
                                    </Typography>
                                ) : (
                                    <Typography
                                        variant="body2"
                                        color="primary"
                                        sx={{ marginTop: 2, cursor: "pointer" }}
                                        onClick={handleResendOtp}
                                    >
                                        Resend OTP
                                    </Typography>
                                )}
                            </Box>
                        </div>
                    )}
                </Box>
            </BootstrapDialog>
        </React.Fragment>
    );
}

export default LoginAtCheckoutModal;
