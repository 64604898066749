import { configureStore } from '@reduxjs/toolkit'
import { StoreSlice } from './slice/StoreSlice'
import { CartSlice } from './slice/CartSlice'
import { AuthSlice } from './slice/AuthSlice'

export const reduxstore = configureStore({
  reducer: {
    auth: AuthSlice.reducer,
    store: StoreSlice.reducer,
    cart: CartSlice.reducer
  },
})