import { Card, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography, IconButton, Paper, Icon } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import Header from '../component/Header/Header'
import FastfoodIcon from '@mui/icons-material/Fastfood';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CancelIcon from '@mui/icons-material/Cancel';
import './OrderHistory.css';
import { useNavigate } from 'react-router-dom';
import axiosApiFunction from '../utils/axiosapifunc';
import { format } from 'date-fns';
import ErrorContext from '../context/ErrorContext';
import BackIcon from "../assets/left.png";
import Spinner from '../component/Spinner/Spinner';

const getIconByStatus = (status) => {
    switch (status) {
        case 'Placed':
            return <ShoppingCartIcon />;
        case 'Acknowledged':
            return <TaskAltIcon />;
        case 'Food Ready':
            return <RestaurantIcon />;
        case 'Dispatched':
            return <LocalShippingIcon />;
        case 'Completed':
            return <CheckCircleIcon />;
        case 'Cancelled':
            return <CancelIcon />;
        default:
            return null;
    }
};

const getClassNameByStatus = (status) => {
    switch (status) {
        case 'Placed':
            return 'order-status-placed';
        case 'Acknowledged':
            return 'order-status-acknowledged';
        case 'Food Ready':
            return 'order-status-food-ready';
        case 'Dispatched':
            return 'order-status-dispatched';
        case 'Completed':
            return 'order-status-completed';
        case 'Cancelled':
            return 'order-status-cancelled';
        default:
            return 'order-status';
    }
};

const OrderStatus = ({ status }) => {
    return (
        <div className={` ${getClassNameByStatus(status)}`}>
            {/* {getIconByStatus(status)} */}
            <span style={{ marginLeft: '10px' }}>{status}</span>
        </div>
    );
};

const OrderHistory = () => {
    const [orderHistory, setOrderHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("");

    const { apiError, setApiError, clearApiError } = useContext(ErrorContext);

    const navigate = useNavigate();
    const handleViewOrder = (orderid) => {
        navigate(`/order-history/${orderid}`)
    }

    const tokenData = JSON.parse(localStorage.getItem('tokenData'));
    const companyId = JSON.parse(localStorage.getItem('companyId'));

    useEffect(() => {
        const callGetOrderHistory = async () => {
            try {
                setIsLoading(true);
                clearApiError(); // Clear previous API error
                const response = await axiosApiFunction('GET', '/api/v1/getorderhistory', { companyid: companyId, phoneno: tokenData.data.phoneno }, tokenData.token);
    
                if (response.status == 200) {
                    if(response.data.length == 0){
                        setMsg("No Data Found")
                    }
                    const sortedData = response.data.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
                    setOrderHistory(sortedData);
                } else {
                    setApiError(`Error: Received status ${response.status}`);
                }
            } catch (error) {
                setApiError(error.message); 
                // navigate('/server-not-reachable'); 
            } finally {
                setIsLoading(false);
            }
        };
    
        callGetOrderHistory();
    }, []);  // Add necessary dependencies

    const handleBack = () => {
        navigate('/catalogue');
      }
    

    return (
        <>
            <Header />
            {isLoading ? (
                <Spinner enabled={isLoading} />
            ) : (
                <>
                    {/* {apiError && <span style={{ color: 'red' }}>{apiError}<br /></span>} */}
                    <Grid container justifyContent="center" className="center-column ">
                        <Grid item xs={12} md={8} lg={6} className="center-column oh-box" mt={2}>
                            <div className="list-container">
                                <div className="backpart oh-backbutton">
                                    <div className="backiconset">
                                        <img src={BackIcon} alt="Back" onClick={handleBack} />
                                    </div>
                                    <div className="brandnameback">
                                        <h3>Back</h3>
                                    </div>
                                </div>
                                {/* Example order card */}
                                {orderHistory.length > 0 ? (
                                    orderHistory.map((order) => (
                                        // <Paper className="card-style" key={order._id}>
                                        //     <ListItem alignItems="flex-start">
                                        //         <ListItemAvatar>
                                        //             <div className='oh-fastfood-icon'><FastfoodIcon className="icon-large" /></div>
                                        //         </ListItemAvatar>
                                        //         <ListItemText
                                        //             primary={
                                        //                 <div className='flex-between'>
                                        //                     <Typography className="order-title">
                                        //                         Order #{order.orderid}
                                        //                     </Typography>
                                        //                     <div className='flex-between'>
                                        //                         <div><Typography variant="body2" className="order-status">
                                        //                             {/* <OrderStatus status={order.orderstatus} /> */}
                                        //                             {/* {order.orderstatus} */}
                                        //                         </Typography></div>
                                        //                         {/* <div className="order-status">&nbsp;&nbsp;<TaskAltIcon /></div> */}
                                        //                     </div>

                                        //                 </div>
                                        //             }
                                        //             secondary={
                                        //                 <React.Fragment>
                                        //                     <div className='flex-between'>
                                        //                         <div>
                                        //                             <Typography component="span" variant="body2" className="order-date">
                                        //                                 {/* {order.created_at.split('.')[0].split('T').join(' ')} */}
                                        //                                 {format(new Date(order.created_at), 'MMMM dd, yyyy hh:mm:ss a')}
                                        //                             </Typography>
                                        //                             <Typography variant="body2" className="history-store-info">
                                        //                                 {order.orderdata.order?.store?.name}
                                        //                             </Typography>
                                        //                         </div>
                                        //                         <div>
                                        //                             <Typography className="order-amount">
                                        //                                 {order.orderdata.order?.store?.currencysymbol} {parseFloat((order.orderdata.order?.details?.order_total || 0).toFixed(2))}
                                        //                             </Typography>
                                        //                         </div>
                                        //                     </div>

                                        //                     <div className='flex-between'>
                                        //                         <Typography variant="body2" className="history-store-info">
                                        //                             Order Type
                                        //                         </Typography>
                                        //                         <Typography variant="body2" className="order-amount">
                                        //                             {order.orderdata.order?.details?.order_type}
                                        //                         </Typography>
                                        //                     </div>

                                        //                     <Divider className="divider-spacing" />
                                        //                     <Grid container className="order-actions">
                                        //                         <IconButton className="icon-btn" onClick={() => handleViewOrder(order.orderid)}>
                                        //                             <RemoveRedEyeIcon /> &nbsp;&nbsp;
                                        //                             <Typography variant="body2" className="view-order">
                                        //                                 View Order
                                        //                             </Typography>
                                        //                         </IconButton>
                                        //                     </Grid>
                                        //                 </React.Fragment>
                                        //             }
                                        //         />
                                        //     </ListItem>
                                        // </Paper>\
                                            <div className="order-detail-dv" key={order._id}>
                                                <div className="order-detail-header">
                                                    <div className="order-detail-head-lft">
                                                        <ul>
                                                            <li>
                                                                {/* <ListItemAvatar> */}
                                                                    <div className='oh-list-icon'>
                                                                        <FastfoodIcon />
                                                                    </div>
                                                                {/* </ListItemAvatar> */}
                                                            </li>
                                                            <li className="order-detail-head-detail"> 
                                                                 #{order.orderid}
                                                                <span>{order.orderdata.order?.store?.name}</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="order-detail-head-rgt">
                                                        <span><OrderStatus status={order.orderstatus} />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="order-detail-body">
                                                    <div className="inner-body-detail style-oh-detail">
                                                        <div className="inner-body-lft">{format(new Date(order.created_at), 'dd-MMM-yyyy hh:mm:ss a')}</div>
                                                        <div className="inner-body-rgt">{order.orderdata.order?.store?.currencysymbol} {parseFloat((order.orderdata.order?.details?.order_total || 0).toFixed(2))}</div>
                                                    </div>
                                                    <div className="inner-body-detail">
                                                        <div className="inner-body-lft">Order Type</div>
                                                        <div className="inner-body-rgt">{order.orderdata.order?.details?.order_type}</div>
                                                    </div>
                                                    <ul className="order-buttons">
                                                        <li onClick={() => handleViewOrder(order.orderid)}>
                                                            <RemoveRedEyeIcon />
                                                            View Order
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                    ))
                                ) : (
                                    <>
                                        <p>{msg}</p>
                                    </>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    )
}

export default OrderHistory;
