import React from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../component/Footer/Footer";
import Logo from "../assets/company-logo.png";
import BackIcon from "../assets/left.png";
import "./RefundAndCancellation.css";

function RefundAndCancellation() {
    const navigate = useNavigate();
    const handleBack = () => {
        navigate("/catalogue");
    };

    return (
        <div>
            <div className="refund-container">
                <div className="static-page-back">
                    <img src={BackIcon} alt="Back" onClick={handleBack} />
                </div>
                <div className="logo-container">
                    <img src={Logo} alt="Company Logo" className="company-logo" />
                </div>
                <h1 className="refund-title">Refund and Cancellation</h1>

                <ol className="refund-list">
                    <p>Welcome to La Milano Pizzeria, brand owned by MFW Foods Private Limited. If you are not entirely satisfied with your purchase, we're here to help. This is a platform where direct business owners (our partners whom you order are La Milano Pizzeria Franchises) are solely responsible for the initiating refund process. Once order placed it cann't be cancelled or refunded. However in certain cases our partners can refund for which you can directly contact them where their contact number is shared upon successful order from your side.</p>
                </ol>
                <h2 className="refund-subtitle">Cancellation Policy</h2>
                <ol className="refund-list">
                    <li>
                        <strong>Incorrect Information:</strong> If incorrect delivery details or
                        contact information is provided, or you are unresponsive, it will be treated
                        as a breach of your authorization for us to process the order on your
                        behalf. In such cases, you will be liable to pay the full order value as
                        damages. We may deduct this from your next order.
                    </li>
                    <li>
                        <strong>Complaints:</strong> For issues such as food spillage, wrong orders,
                        foreign objects, or poor quality, proof must be provided to resolve the
                        matter.
                    </li>
                    <li>
                        <strong>Item Unavailability:</strong> If an item is unavailable after your
                        order is confirmed, we will inform you and offer the following options:
                        <ul>
                            <li>
                                <strong>If Paid:</strong> You may cancel the order and receive a
                                full refund.
                            </li>
                            <li>
                                <strong>Cash on Delivery:</strong> You can cancel the order without
                                any charges.
                            </li>
                        </ul>
                        We reserve the right to cancel your order in cases where:
                        <ul>
                            <li>We cannot reach you for confirmation.</li>
                            <li>The delivery address is outside our service area.</li>
                            <li>Necessary information for delivery is missing.</li>
                            <li>The items are out of stock.</li>
                        </ul>
                    </li>
                </ol>
                <h2 className="refund-subtitle">Refunds</h2>
                <ol className="refund-list">
                    <p>
                        Refunds will be processed according to your payment method and your bank's
                        policies. Refund timelines are as follows:
                    </p>
                    <table className="refund-table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Payment Method</th>
                                <th>Refund Method</th>
                                <th>Refund Time-frame</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Credit Card/ Debit Card</td>
                                <td>Credit Card/ Debit Card</td>
                                <td>5-7 business days</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Net Banking</td>
                                <td>Net Banking Account (Credited to Bank Account)</td>
                                <td>5-7 business days</td>
                            </tr>
                        </tbody>
                    </table>
                    <p>
                        If you haven't received your refund within the mentioned timeframe, please
                        contact your card issuer or bank.
                    </p>
                </ol>
                <h2 className="refund-subtitle">For Further Assistance</h2>
                <ol className="refund-list">
                    <p>
                        If you have any questions regarding returns, refunds, or order complaints,
                        please contact the partner directly. La Milano Pizzeria (MFWFPL) takes no
                        responsibility for partners' actions.
                    </p>
                    <p>We always strive to provide the best possible service and quality!</p>
                </ol>
            </div>
            <Footer />
        </div>
    );
}

export default RefundAndCancellation;
