import { useEffect, useRef, useState } from "react";
import "./GetOtp.css";

const GetOtp = ({ length = 4, onOtpChange, submitButtonRef }) => {
    const [otp, setOtp] = useState(new Array(length).fill(""));
    const inputRefs = useRef([]);

    useEffect(() => {
        if (inputRefs.current[0]) {
            inputRefs.current[0].focus();
        }
    }, []);

    const handleChange = (index, e) => {
        const value = e.target.value;
        if (isNaN(value)) return;

        const newOtp = [...otp];

        newOtp[index] = value.substring(value.length - 1);
        setOtp(newOtp);

        const combinedOtp = newOtp.join("");
        // if (combinedOtp.length === length) {
            onOtpChange(combinedOtp);
        // }

        if (combinedOtp.length === length && submitButtonRef?.current) {
            submitButtonRef.current.focus(); // Focus on the "Submit" button when OTP is complete
        }

        if (value && index < length - 1 && inputRefs.current[index + 1]) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleClick = (index) => {
        inputRefs.current[index].setSelectionRange(1, 1);

        if (index > 0 && !otp[index - 1]) {
            inputRefs.current[otp.indexOf("")].focus();
        }
    };

    const handleKeyDown = (index, e) => {
        if (
            e.key === "Backspace" &&
            !otp[index] &&
            index > 0 &&
            inputRefs.current[index - 1]
        ) {
            inputRefs.current[index - 1].focus();
        }
    };

    return (
        <div className="d-flex flex-row justify-content-center set-otp-box">
            {otp.map((value, index) => {
                return (
                    <input
                        key={index}
                        type="tel"
                        ref={(input) => (inputRefs.current[index] = input)}
                        value={value}
                        onChange={(e) => handleChange(index, e)}
                        onClick={() => handleClick(index)}
                        onKeyDown={(e) => handleKeyDown(index, e)}
                        className="m-2 text-center rounded"
                    />
                );
            })}
        </div>
    );
};

export default GetOtp;
